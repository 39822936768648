import React from 'react'

import ImgX from "../assets/about.webp"
import Produk1 from "../assets/produk1.jpg"
import Produk2 from "../assets/produk2.jpg"
import Produk3 from "../assets/produk3.jpg"

export default function Home() {
  return (
    <div>
<body class="bg-gray-100">

    <nav class="bg-gray-800 text-white py-4">
        <div class="container mx-auto flex justify-between items-center">
            <a href="/" class="text-2xl font-semibold">Design-Your-Own</a>
            <ul class="flex space-x-6">
                <li><a href="#about" class="hover:text-palette-1">About</a></li>
                <li><a href="#why-choose-us" class="hover:text-palette-2">Why Choose Us</a></li>
                <li><a href="#experiences" class="hover:text-palette-3">Experiences</a></li>
                <li><a href="#contact" class="hover:text-palette-4">Contact</a></li>
            </ul>
        </div>
    </nav>

 
    <header class="bg-palette-1 text-white py-16 px-4">
        <div class="container mx-auto text-center">
            <h1 class="text-4xl font-semibold mb-4">Elevate Your Design with Us</h1>
            <p class="text-lg">Creating Art Through Design</p>
            <a href="#contact" class="bg-palette-3 text-palette-1 hover:bg-opacity-75 py-2 px-6 mt-8 rounded-full inline-block transition duration-300">Contact Us</a>
        </div>
    </header>


    <section id="about" class="py-16">
        <div class="container mx-auto flex items-center">
            <div class="w-1/2">
                <img src={ImgX} alt="About Design-Your-Own" class="rounded-full shadow-lg"/>
            </div>
            <div class="w-1/2 px-8">
                <h2 class="text-3xl font-semibold mb-4">About Us</h2>
                <p class="text-gray-700">Design-Your-Own is a design studio passionate about turning your ideas into stunning visual creations. With a team of talented designers, we bring your visions to life.</p>
            </div>
        </div>
    </section>


    <section id="why-choose-us" class="bg-gray-100 py-16">
        <div class="container mx-auto text-center">
            <h2 class="text-3xl font-semibold mb-8">Why Choose Us</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
       
                <div class="bg-white rounded-lg shadow-lg p-6">
                    <h3 class="text-xl font-semibold mb-2">Creative Excellence</h3>
                    <p class="text-gray-700">Our team is dedicated to pushing creative boundaries to deliver unique and memorable designs.</p>
                </div>
              
                <div class="bg-white rounded-lg shadow-lg p-6">
                    <h3 class="text-xl font-semibold mb-2">Client-Centric Approach</h3>
                    <p class="text-gray-700">We prioritize your vision and collaborate closely to ensure your design needs are met.</p>
                </div>
          
                <div class="bg-white rounded-lg shadow-lg p-6">
                    <h3 class="text-xl font-semibold mb-2">Timely Delivery</h3>
                    <p class="text-gray-700">We understand the importance of deadlines and work diligently to deliver on time.</p>
                </div>
            </div>
        </div>
    </section>

  
    <section id="experiences" class="py-16">
        <div class="container mx-auto text-center">
            <h2 class="text-3xl font-semibold mb-8">Our Experiences</h2>
            <p class="text-gray-700">With years of experience, we've worked on diverse projects, from branding to digital design.</p>
        
        </div>
    </section>


    <section id="contact" class="bg-palette-5 text-white py-16">
        <div class="container mx-auto text-center">
            <h2 class="text-3xl font-semibold mb-8">Let's Create Together</h2>
            <p class="text-lg">Have a project in mind? Reach out to us and let's bring your ideas to life.</p>
            <a href="mailto:contact@design-your-own.com" class="bg-palette-2 text-palette-1 hover:bg-opacity-75 py-2 px-6 mt-8 rounded-full inline-block transition duration-300">Contact Us</a>
        </div>
    </section>

   
    <footer class="bg-gray-800 text-white py-8">
        <div class="container mx-auto text-center">
            <p>&copy; 2023 Design-Your-Own. All rights reserved.</p>
        </div>
    </footer>
</body>
    </div>
  )
}
